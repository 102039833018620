import { AddressInfo } from '../api'

export function getAddressString (address: Omit<AddressInfo, 'id' | 'lat' | 'long'>, full: boolean = false, includeContact: boolean = false) {
  if (!address) {
    return ''
  }
  let address_string: string = ''
  if (includeContact && address.name && address.name.length > 0) {
    address_string += `${address.name}, `
  }
  if (includeContact && address.email && address.email.length > 0) {
    address_string += `${address.email}, `
  }
  if (address.address_1 && address.address_1.length > 0) {
    address_string += `${address.address_1}, `
  }
  if (address.address_2 && address.address_2.length > 0) {
    address_string += `${address.address_2}, `
  }
  if (address.town && address.town.length > 0) {
    address_string += `${address.town}, `
  }
  if (address.county && address.county.length > 0) {
    address_string += `${address.county}, `
  }
  if (full) {
    if (address.country && address.country.length > 0) {
      address_string += `${address.country}, `
    }
    if (address.postcode && address.postcode.length > 0) {
      address_string += `${address.postcode}`
    }
  }
  if (address_string.length === 0) {
    address_string = 'N/A'
  }
  return address_string
}
