<template>
  <section class="section">
    <div class="row center-block">
      <div class="col-md-12">
        <div class="box box-info">
          <div class="box-header">
            <div class="content" style="margin-bottom:2.5rem">
              <b-field class="is-pulled-right">
                <b-radio-button
                  v-model="accountRegion"
                  native-value="GB"
                  type="is-link"
                  @input="updateAccountRegion"
                >
                  <span class="icon">🇬🇧</span>
                  <span>GB</span>
                </b-radio-button>

                <b-radio-button
                  v-model="accountRegion"
                  native-value="US"
                  type="is-link"
                  @input="updateAccountRegion"
                >
                  <span class="icon">🇺🇸</span>
                  <span>US</span>
                </b-radio-button>
              </b-field>
              <h2>
                Sales
                <span class="tag is-danger">SUPERDUPERUSER</span>
              </h2>

              <div class="box-tools is-pulled-right">
                <h4 class="has-text-link">
                  <button type="button" class="btn btn-box-tool has-text-dark" @click.prevent="getSubscriptionsInfo">
                    <i class="fas fa-sync" />
                  </button> 30-day total: {{ formatCurrency(totalSales, accountRegion === 'GB' ? 'gbp' : 'usd' ) }}
                </h4>
              </div>
            </div>
            <br class="clearfix">
            <div class="columns clearfix" style="overflow-x: scroll;">
              <div class="column is-12 custom-6-columns content">
                <div id="salesChart" class="ct-chart" />
              </div>
              <div class="column is-12 custom-6-columns">
                <b-table
                  v-if="salesData"
                  :data.sync="salesData"
                  :paginated="true"
                  :per-page="4"
                  :mobile-cards="true"
                  :striped="true"
                  :selectable="false"
                  :sort="true"
                  default-sort-direction="desc"
                  default-sort="date"
                >
                  <template slot-scope="props">
                    <b-table-column field="date" label="Date" sortable>
                      <p>{{ formattedDate(props.row.date) }}</p>
                    </b-table-column>
                    <b-table-column v-for="currency of Currency" :key="`totals-by-currency-${currency}`" :visible="regionCurrencies.includes(currency)" field="sales" :label="currency">
                      <p class="subscription-by-date">
                        <b-tooltip label="Subscriptions">
                          {{ formatCurrency(props.row[currency].subscriptions, currency) }}
                        </b-tooltip>
                      </p>
                      <p class="merch-by-date">
                        <b-tooltip label="Merchandise">
                          {{ formatCurrency(props.row[currency].sales, currency) }}
                        </b-tooltip>
                      </p>
                      <p class="total-by-date">
                        <b-tooltip label="Total">
                          {{ formatCurrency(props.row[currency].sales + props.row[currency].subscriptions, currency) }}
                        </b-tooltip>
                      </p>
                    </b-table-column>
                  </template>
                  <template #subheading="{column}">
                    <b-tooltip
                      :active="column.newKey !== targetCurrency"
                      multilined
                      :label="getTotalTooltip(column.newKey)"
                    >
                      <span v-if="Currency.includes(column.newKey)">{{ formatCurrency(currencyTotals[column.newKey].total, column.newKey) }}</span>
                    </b-tooltip>
                  </template>
                  <template slot="empty">
                    <section class="section">
                      <div class="content has-text-grey has-text-centered content">
                        <p>
                          <b-icon
                            custom-class="far"
                            pack="fa"
                            icon="frown"
                            size="is-large"
                          />
                        </p>
                        <p>Nothing here.</p>
                      </div>
                    </section>
                  </template>
                </b-table>
              </div>
            </div>
          </div>
          <!-- /.box-header -->
          <div class="box-body">
            <div class="columns">
              <div class="column is-6">
                <b-field label="Select a date">
                  <b-datepicker
                    v-model="from"
                    placeholder="From..."
                    icon="calendar"
                    pack="mdi"
                    position="is-top-right"
                    @input="fromDateDidChange"
                  />
                </b-field>
              </div>
              <div class="column is-6">
                <b-field label="Select a date">
                  <b-datepicker
                    v-model="to"
                    placeholder="To..."
                    icon="calendar"
                    pack="mdi"
                    position="is-top-right"
                    @input="dateDidChange"
                  />
                </b-field>
              </div>
            </div>
            <div>
              <div class="field is-pulled-left">
                <b-switch
                  v-model="payments"
                  type="is-success"
                  @input="dateDidChange"
                >
                  Payments
                </b-switch>
              </div>
              <!-- <button v-if="to" class="button is-danger is-pulled-right" style="margin-left: 5px;" @click="getDebtors">
                Debtors to date
              </button>
              <button v-if="from && to" class="button is-info is-pulled-right" style="margin-left: 5px;" @click="getXeroSubs">
                Subscriptions Journal
              </button>
              <button v-if="from && to" class="button is-link is-pulled-right" style="margin-left: 5px;" @click="getXeroMerch">
                Merch Sales Journal
              </button> -->
            </div>
            <div>
              <table class="table">
                <tr>
                  <th>
                    &nbsp;
                  </th>
                  <th v-for="currency in Currency" v-show="regionCurrencies.includes(currency)" :key="`summary-table-currency-${currency}`">
                    <span class="capitalize">{{ currency }}
                    </span>
                  </th>
                </tr>
                <tr>
                  <th>Total subscriptions net:</th>
                  <td v-for="currency in Currency" v-show="regionCurrencies.includes(currency)" :key="`summary-table-subscription-sales-${currency}`">
                    {{ formatCurrency((salesTotals && salesTotals.subscriptions.currencies.find(c => c.currency === currency)) ? salesTotals.subscriptions.currencies.find(c => c.currency === currency).sales : 0, currency) }}
                  </td>
                </tr>
                <tr v-if="payments">
                  <th>Total subscriptions tax:</th>
                  <td v-for="currency in Currency" v-show="regionCurrencies.includes(currency)" :key="`summary-table-subscription-tax-${currency}`">
                    {{ formatCurrency((salesTotals && salesTotals.subscriptions.currencies.find(c => c.currency === currency)) ? salesTotals.subscriptions.currencies.find(c => c.currency === currency).tax : 0, currency) }}
                  </td>
                </tr>
                <tr v-if="payments && accountRegion === 'GB'">
                  <th>Box Eight EU subscriptions:</th>
                  <td>&pound;<span v-if="salesTotals">{{ salesTotals.subscriptions.boxEight }}</span><span v-else>0.00</span></td><td>$<span>0.00</span></td><td>A$<span>0.00</span></td><td>NZ$<span>0.00</span></td><td>€<span>0.00</span></td><td>R<span>0.00</span></td>
                </tr>
                <tr>
                  <th>Total merch sales net:</th>
                  <td v-for="currency in Currency" v-show="regionCurrencies.includes(currency)" :key="`summary-table-merch-sales-${currency}`">
                    {{ formatCurrency((salesTotals && salesTotals.sales.currencies.find(c => c.currency === currency)) ? salesTotals.sales.currencies.find(c => c.currency === currency).sales : 0, currency) }}
                  </td>
                </tr>
                <tr v-if="payments">
                  <th>Total merch sales tax:</th>
                  <td v-for="currency in Currency" v-show="regionCurrencies.includes(currency)" :key="`summary-table-merch-tax-${currency}`">
                    {{ formatCurrency((salesTotals && salesTotals.sales.currencies.find(c => c.currency === currency)) ? salesTotals.sales.currencies.find(c => c.currency === currency).tax : 0, currency) }}
                  </td>
                </tr>
              </table>
            </div>
            <div class="content">
              <h3>Split</h3>
              <p>All sales, shared between packages. International sales based on final amount only and will only be counted below once final currency conversion has taken place.</p>
              <b-switch v-model="lsten">
                Literacy Shed+ @{{ lsten?'10' : '100' }}%
              </b-switch>
              <table class="table">
                <tr>
                  <th>Product</th><th>Past Period</th><th>This Period</th><th>Multiplier</th>
                </tr><tr>
                  <th>Spelling</th>
                  <td>{{ accountRegion === 'US' ? '$' : '&pound;' }}<span v-if="salesTotals">{{ salesTotals.subscriptions.pastSplit.spelling.toLocaleString() }}</span><span v-else>0.00</span></td>
                  <td>{{ accountRegion === 'US' ? '$' : '&pound;' }}<span v-if="salesTotals">{{ salesTotals.subscriptions.split.spelling.toLocaleString() }}</span><span v-else>0.00</span></td>
                  <td>x<span v-if="salesTotals && salesTotals.subscriptions.split.spelling && salesTotals.subscriptions.pastSplit.spelling">{{ (salesTotals.subscriptions.split.spelling / salesTotals.subscriptions.pastSplit.spelling).toFixed(2) }}</span><span v-else>0</span></td>
                </tr>
                <tr>
                  <th>Phonics</th>
                  <td>{{ accountRegion === 'US' ? '$' : '&pound;' }}<span v-if="salesTotals">{{ salesTotals.subscriptions.pastSplit.phonics.toLocaleString() }}</span><span v-else>0.00</span></td>
                  <td>{{ accountRegion === 'US' ? '$' : '&pound;' }}<span v-if="salesTotals">{{ salesTotals.subscriptions.split.phonics.toLocaleString() }}</span><span v-else>0.00</span></td>
                  <td>x<span v-if="salesTotals && salesTotals.subscriptions.split.phonics && salesTotals.subscriptions.pastSplit.phonics">{{ (salesTotals.subscriptions.split.phonics / salesTotals.subscriptions.pastSplit.phonics).toFixed(2) }}</span><span v-else>0</span></td>
                </tr>
                <tr>
                  <th>Maths</th>
                  <td>{{ accountRegion === 'US' ? '$' : '&pound;' }}<span v-if="salesTotals">{{ salesTotals.subscriptions.pastSplit.maths.toLocaleString() }}</span><span v-else>0.00</span></td>
                  <td>{{ accountRegion === 'US' ? '$' : '&pound;' }}<span v-if="salesTotals">{{ salesTotals.subscriptions.split.maths.toLocaleString() }}</span><span v-else>0.00</span></td>
                  <td>x<span v-if="salesTotals && salesTotals.subscriptions.split.maths && salesTotals.subscriptions.pastSplit.maths">{{ (salesTotals.subscriptions.split.maths / salesTotals.subscriptions.pastSplit.maths).toFixed(2) }}</span><span v-else>0</span></td>
                </tr>
                <tr>
                  <th>Literacy Shed +</th>
                  <td>{{ accountRegion === 'US' ? '$' : '&pound;' }}<span v-if="salesTotals">{{ (salesTotals.subscriptions.pastSplit.litshed * (lsten ? 0.1 : 1)).toLocaleString() }}</span><span v-else>0.00</span></td>
                  <td>{{ accountRegion === 'US' ? '$' : '&pound;' }}<span v-if="salesTotals">{{ (salesTotals.subscriptions.split.litshed * (lsten ? 0.1 : 1)).toLocaleString() }}</span><span v-else>0.00</span></td>
                  <td>x<span v-if="salesTotals && salesTotals.subscriptions.split.litshed && salesTotals.subscriptions.pastSplit.litshed">{{ (salesTotals.subscriptions.split.litshed / salesTotals.subscriptions.pastSplit.litshed).toFixed(2) }}</span><span v-else>0</span></td>
                </tr>
                <tr>
                  <th>Merch</th>
                  <td>{{ accountRegion === 'US' ? '$' : '&pound;' }}<span v-if="salesTotals">{{ salesTotals.sales.categories.previous.merch.toLocaleString() }}</span><span v-else>0.00</span></td>
                  <td>{{ accountRegion === 'US' ? '$' : '&pound;' }}<span v-if="salesTotals">{{ salesTotals.sales.categories.current.merch.toLocaleString() }}</span><span v-else>0.00</span></td>
                  <td>x<span v-if="salesTotals && salesTotals.sales.categories.previous.merch && salesTotals.sales.categories.current.merch">{{ (salesTotals.sales.categories.current.merch / salesTotals.sales.categories.previous.merch).toFixed(2) }}</span><span v-else>0</span></td>
                </tr>
                <tr>
                  <th>Training</th>
                  <td>{{ accountRegion === 'US' ? '$' : '&pound;' }}<span v-if="salesTotals">{{ salesTotals.sales.categories.previous.training.toLocaleString() }}</span><span v-else>0.00</span></td>
                  <td>{{ accountRegion === 'US' ? '$' : '&pound;' }}<span v-if="salesTotals">{{ salesTotals.sales.categories.current.training.toLocaleString() }}</span><span v-else>0.00</span></td>
                  <td>x<span v-if="salesTotals && salesTotals.sales.categories.previous.training && salesTotals.sales.categories.current.training">{{ (salesTotals.sales.categories.current.training / salesTotals.sales.categories.previous.training).toFixed(2) }}</span><span v-else>0</span></td>
                </tr>
                <tr>
                  <th>Resources</th>
                  <td>{{ accountRegion === 'US' ? '$' : '&pound;' }}<span v-if="salesTotals">{{ salesTotals.sales.categories.previous.resources.toLocaleString() }}</span><span v-else>0.00</span></td>
                  <td>{{ accountRegion === 'US' ? '$' : '&pound;' }}<span v-if="salesTotals">{{ salesTotals.sales.categories.current.resources.toLocaleString() }}</span><span v-else>0.00</span></td>
                  <td>x<span v-if="salesTotals && salesTotals.sales.categories.previous.resources && salesTotals.sales.categories.current.resources">{{ (salesTotals.sales.categories.current.resources / salesTotals.sales.categories.previous.resources).toFixed(2) }}</span><span v-else>0</span></td>
                </tr>
                <tr v-if="salesTotals">
                  <th>
                    TOTALS
                  </th>
                  <td>{{ accountRegion === 'US' ? '$' : '&pound;' }}{{ (salesTotals.subscriptions.pastSplit.spelling + salesTotals.subscriptions.pastSplit.phonics + salesTotals.subscriptions.pastSplit.maths + salesTotals.subscriptions.pastSplit.litshed*(lsten ? 0.1 : 1) + salesTotals.sales.categories.previous.merch + salesTotals.sales.categories.previous.training + salesTotals.sales.categories.previous.resources).toLocaleString() }}</td>
                  <td>{{ accountRegion === 'US' ? '$' : '&pound;' }}{{ (salesTotals.subscriptions.split.spelling + salesTotals.subscriptions.split.phonics + salesTotals.subscriptions.split.maths + salesTotals.subscriptions.split.litshed*(lsten ? 0.1 : 1) + salesTotals.sales.categories.current.merch + salesTotals.sales.categories.current.training + salesTotals.sales.categories.current.resources).toLocaleString() }}</td>
                  <td>
                    x{{ ((salesTotals.subscriptions.split.spelling + salesTotals.subscriptions.split.phonics + salesTotals.subscriptions.split.maths + salesTotals.sales.categories.current.merch + salesTotals.sales.categories.current.training + salesTotals.sales.categories.current.resources + salesTotals.subscriptions.split.litshed*(lsten ? 0.1 : 1)) / (salesTotals.subscriptions.pastSplit.spelling + salesTotals.subscriptions.pastSplit.phonics + salesTotals.subscriptions.pastSplit.maths + salesTotals.sales.categories.previous.merch + salesTotals.sales.categories.previous.training + salesTotals.sales.categories.previous.resources + salesTotals.subscriptions.pastSplit.litshed*(lsten ? 0.1 : 1))).toFixed(2) }}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import moment from 'moment'
import Chartist from 'chartist'
import VueChartist from 'v-chartist'
import { request } from '@/edshed-common/api'
import { Currency } from '@/edshed-common/api/types/currency'
import ComponentHelper from '@/mixins/ComponentHelper'
// import $ from 'jquery'

export default {
  name: 'FinancialsView',
  components: {
    VueChartist,
    Chartist
  },
  mixins: [ComponentHelper],
  data (router) {
    return {
      filteredSchoolsData: null,
      salesData: null,
      salesTotals: null,
      response: '',
      searchPhrase: '',
      from: null,
      to: null,
      payments: false,
      accountRegion: 'GB',
      lsten: true,
      Currency
    }
  },
  computed: {
    totalSales () {
      if (!this.salesData) {
        return 0
      }

      return this.salesData.reduce((total, row) => {
        const values = Currency.map(c => row[c].sales_converted + row[c].subscriptions_converted)
        return total + values.reduce((subTot, val) => subTot + val, 0)
      }, 0)
    },
    regionCurrencies () {
      if (this.accountRegion === 'GB') {
        return Currency
      } else {
        return 'usd'
      }
    },
    targetCurrency () {
      return this.accountRegion === 'GB' ? 'gbp' : 'usd'
    },
    currencyTotals () {
      const totals = {}
      for (const curr of Currency) {
        totals[curr] = this.salesData.reduce((agg, row) => {
          const currencyRow = row[curr]
          const total = currencyRow.sales + currencyRow.subscriptions
          const convertedTotal = currencyRow.sales_converted + currencyRow.subscriptions_converted

          return { total: agg.total + total, convertedTotal: agg.convertedTotal + convertedTotal, pendingConversions: agg.pendingConversions + currencyRow.pending_conversions }
        }, { total: 0, convertedTotal: 0, pendingConversions: 0 })
      }

      return totals
    }
  },
  mounted () {
    if (!this.$store.state.user.superduperuser) {
      this.$router.push('/noaccess')
    } else {
      this.getSubscriptionsInfo()
    }
  },
  methods: {
    updateAccountRegion () {
      console.log('RESET')
      this.salesData = null
      this.salesTotals = null
      this.from = null
      this.to = null
      this.payments = false
      this.getSubscriptionsInfo()
    },
    getDebtors () {
      request('GET', 'superuser/getdebtors?to=' + this.to.toISOString() + '&accountRegion=' + this.accountRegion, null)
        .then((response) => {
          // console.log('readerData: ' + this.readerData)
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'debtors.csv')
          document.body.appendChild(link)
          link.click()
        })
        .catch((error) => {
          console.log(error)
          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }
          this.response = 'Details incorrect'
        })
    },
    getXeroSubs () {
      request('GET', 'superuser/xerosubs?from=' + this.from.toISOString() + '&to=' + this.to.toISOString() + '&accountRegion=' + this.accountRegion, null)
        .then((response) => {
          // console.log('readerData: ' + this.readerData)
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'subscriptions.csv')
          document.body.appendChild(link)
          link.click()
        })
        .catch((error) => {
          console.log(error)
          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }
          this.response = 'Details incorrect'
        })
    },
    getXeroMerch () {
      request('GET', 'superuser/xeromerch?from=' + this.from.toISOString() + '&to=' + this.to.toISOString() + '&accountRegion=' + this.accountRegion, null)
        .then((response) => {
          // console.log('readerData: ' + this.readerData)
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'merch.csv')
          document.body.appendChild(link)
          link.click()
        })
        .catch((error) => {
          console.log(error)
          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }
          this.response = 'Details incorrect'
        })
    },
    getSubscriptionsInfo () {
      console.log('GET INFO')
      request('GET', 'superuser/salesinfo?accountRegion=' + this.accountRegion, null)
        .then((response) => {
          const dates = Object.keys(response.data.sales)
          const data = dates.map(d => ({ date: d, ...response.data.sales[d] }))
          this.salesData = data
          this.renderChart()
          // console.log('readerData: ' + this.readerData)
        })
        .catch((error) => {
          console.log(error)
          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }
          this.response = 'Details incorrect'
        })
    },
    renderChart () {
      const series = []

      for (const currency of Currency) {
        const dt = []

        for (let i = 0; i < this.salesData.length; i++) {
          const el = { x: moment(this.salesData[i].date), y: this.salesData[i][currency].subscriptions_converted + this.salesData[i][currency].sales_converted }
          dt.push(el)
        }

        const s = { name: `Sales ${currency}`, data: dt }
        series.push(s)
      }

      this.chart = new Chartist.Line('#salesChart', { series },
        {
          axisX: {
            type: Chartist.FixedScaleAxis,
            divisor: 10,
            labelInterpolationFnc (value) {
              return moment(value).format('MMM D')
            }
          },
          fullWidth: true,
          chartPadding: {
            right: 40
          },
          lineSmooth: Chartist.Interpolation.cardinal({
            tension: 0.1,
            fillHoles: true
          }),
          low: 0,
          height: 300,
          plugins: [
            // Chartist.plugins.legend()
          ]
        })
    },
    fromDateDidChange () {
      console.log('change')
      this.to = new Date(this.from)
      this.to.setDate(this.to.getDate() + 1)
      if (this.from && this.to) {
        console.log(this.from.toISOString())
        console.log(this.to.toISOString())
        const params = { from: this.from.toISOString(), to: this.to.toISOString(), payments: this.payments ? 1 : 0 }
        request('GET', 'superuser/salestotals?from=' + this.from.toISOString() + '&to=' + this.to.toISOString() + '&payments=' + (this.payments ? 1 : 0) + '&accountRegion=' + this.accountRegion, null)
          .then((response) => {
            const data = response.data
            this.salesTotals = data
            console.log('sales data: ' + data)
          })
          .catch((error) => {
            console.log(error)
            if (error.response.status === 403) {
              console.log('FORBIDDEN')
              this.$router.push('/logout')
            }
            this.response = 'Details incorrect'
          })
      }
    },
    dateDidChange () {
      console.log('change')
      if (this.from && this.to) {
        console.log(this.from.toISOString())
        console.log(this.to.toISOString())
        const params = { from: this.from.toISOString(), to: this.to.toISOString(), payments: this.payments ? 1 : 0 }
        request('GET', 'superuser/salestotals?from=' + this.from.toISOString() + '&to=' + this.to.toISOString() + '&payments=' + (this.payments ? 1 : 0) + '&accountRegion=' + this.accountRegion, null)
          .then((response) => {
            const data = response.data
            this.salesTotals = data
            console.log('sales data: ' + data)
          })
          .catch((error) => {
            console.log(error)
            if (error.response.status === 403) {
              console.log('FORBIDDEN')
              this.$router.push('/logout')
            }
            this.response = 'Details incorrect'
          })
      }
    },
    formattedDate (d) {
      return moment(d).format('DD/MM/YYYY')
    },
    toggleLoading () {
      this.loading = (this.loading === '') ? 'loading' : ''
    },
    getTotalTooltip (currency) {
      const data = this.currencyTotals[currency]

      if (!data) {
        return ''
      }

      if (data.pendingConversions) {
        return `as ${this.targetCurrency}: ${this.formatCurrency(data.convertedTotal, this.targetCurrency)}.
        + ${data.pendingConversions} items awaiting conversion.`
      } else {
        return `as ${this.targetCurrency}: ${this.formatCurrency(data.convertedTotal, this.targetCurrency)}.`
      }
    }
  }
}

//   router.route('/superuser/markpaid')
//   .post(auth.isAuthenticated, auth.requireSubscription, subscriptions.suMarkPaid)
// router.route('/superuser/deactivatesubscription')
//   .post(auth.isAuthenticated, auth.requireSubscription, subscriptions.suMarkDeactivated)
// router.route('/superuser/setexpiry')
//   .post(auth.isAuthenticated, auth.requireSubscription, subscriptions.suSetExpiry)
// router.route('/superuser/setstage')
</script>
<style scoped>
  .capitalize {
    text-transform: uppercase;
  }

  .subscription-by-date {
    background-color: lightgoldenrodyellow;
    font-size: 70%;
  }

  .merch-by-date {
    background-color: lightcyan;
    font-size: 70%;
  }

  .total-by-date {
    border-top: 1px solid gray;
    background-color: #e0f0e3;
    font-weight: 600;
    font-size: small;
  }

  .b-tooltip:after {
  white-space: pre !important;
  }

  @media (min-width: 1200px) {
  .custom-6-columns {
    max-width: 50%;
  }
}
</style>
