
























































import { Api, AddressInfo } from '@/edshed-common/api'
import ComponentHelper from '@/mixins/ComponentHelper'
import { Component, Mixins, Prop } from 'vue-property-decorator'
import { getAddressString } from '@/edshed-common/utils/address'

@Component({
  name: 'AddressModal',
  components: {
  }
})

export default class AddressModal extends Mixins(ComponentHelper) {
  @Prop({ default: null, required: true }) addresses!: AddressInfo[] | null
  @Prop({ default: null, required: true }) billing_address!: AddressInfo | null
  @Prop({ default: null }) invoice_id!: number | null
  @Prop({ default: null }) subscription_id!: number | null
  @Prop({ default: 'invoice' }) type!: 'subscription' | 'invoice'

  getAddressString = getAddressString

  selectedAddress: number | null = null

  get foundBillingAddress () {
    return this.billing_address && this.addresses?.find(a => a.id === this.billing_address?.id)
  }

  get single_address () {
    return this.addresses && this.addresses.length === 1 && this.billing_address?.id === this.addresses[0].id
  }

  get filtered_addresses () {
    return this.addresses?.filter(a => a.id !== this.billing_address?.id)
  }

  close () {
    this.$emit('close')
  }

  async submit () {
    try {
      if (!this.selectedAddress || (this.billing_address && (this.selectedAddress === this.billing_address.id))) {
        return this.$buefy.toast.open({
          message: 'Please select a different address',
          position: 'is-bottom',
          type: 'is-danger'
        })
      }
      if (this.type === 'invoice' && this.invoice_id) {
        await Api.updateInvoice(this.invoice_id, { billing_address_id: this.selectedAddress })
        this.$emit('refresh-invoices')
      } else if (this.subscription_id) {
        const subscription = await Api.updateSubscriptionBillingAddress(this.subscription_id, this.selectedAddress)
        this.$emit('refresh-subscription', subscription)
      }
      this.$emit('close')
    } catch (err) {
      this.$buefy.toast.open({
        message: `Could not update ${this.type}`,
        position: 'is-bottom',
        type: 'is-danger'
      })
    }
  }
}
