

















































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Mixins } from 'vue-property-decorator'
import LinkWonde from '@/components/views/components/LinkWonde.vue'
import LinkD6 from '@/components/views/components/LinkD6.vue'
import LinkDistrict from '@/components/views/components/LinkDistrict.vue'
import AddFreeSubscription from '@/components/views/components/AddFreeSubscription.vue'
import ResourceDownloadsModal from '@/components/views/components/ResourceDownloadsModal.vue'
import StripeEvents from '@/components/views/components/StripeEvents.vue'
import { Api } from '@/edshed-common/api/methods'
import { SubscriptionPlanAccountRegion, SubscriptionPlanCurrency, SubscriptionPlanPeriod, SubscriptionPlanGroup, SubscriptionPlanAccountType, SubscriptionLevelType } from '@/edshed-common/subscriptionPackages'
import MerchSale from '@/components/views/components/MerchSale.vue'
import { request, isPersonal, SubscriptionPlanPriceType, SubscriptionProductType, SuperUserSchoolSearchResult, StripeInvoiceStatus, TableState, StripeInvoiceAccountRegion, CleverStatus, SchoolOrgType, OneRosterProvider, SubscriptionPlanInfo, GetSchoolSubscriptionsParams, Subscription, NoteInfo, EditNoteRequest, SchoolUpdateRequest, ScheduledSubscriptionInfo, XeroContactInfo, MerchPurchase, PagedResults, SalesPerson, AddressToEdit } from '@/edshed-common/api'
import SyncWithWondeButton from '@/edshed-common/components/SyncWithWondeButton.vue'
import SubscriptionCard from '@/components/views/components/SubscriptionCard.vue'
import ClassLinkAddOrg from '@/components/views/components/ClassLinkAddOrg.vue'
import GG4LAddOrg from '@/components/views/components/GG4LAddOrg.vue'
import Notes from '@/components/views/components/Notes.vue'
import ComponentHelper from '@/mixins/ComponentHelper'
import D6ActivateButton from '@/components/views/components/D6ActivateButton.vue'
import { getAddressString } from '@/edshed-common/utils/address'
import XeroContactSearch from './components/XeroContactSearch.vue'

@Component({
  name: 'Schools',
  components: {
    AddFreeSubscription,
    ClassLinkAddOrg,
    GG4LAddOrg,
    LinkDistrict,
    LinkWonde,
    LinkD6,
    MerchSale,
    ResourceDownloadsModal,
    StripeEvents,
    SubscriptionCard,
    SyncWithWondeButton,
    XeroContactSearch,
    Notes,
    D6ActivateButton
  }
})
export default class Schools extends Mixins(ComponentHelper) {
  SubscriptionPlanAccountRegion = SubscriptionPlanAccountRegion
  SubscriptionPlanCurrency = SubscriptionPlanCurrency
  SubscriptionPlanGroup = SubscriptionPlanGroup
  SubscriptionPlanPeriod = SubscriptionPlanPeriod
  SubscriptionPlanPriceType = SubscriptionPlanPriceType
  SubscriptionProductType = SubscriptionProductType
  SubscriptionPlanAccountType = SubscriptionPlanAccountType
  SubscriptionLevelType = SubscriptionLevelType

  schoolsData: SuperUserSchoolSearchResult[] = []

  subscriptionsMap: Map<number, Subscription[]> = new Map()

  futureSubscriptionsMap: Map<number, ScheduledSubscriptionInfo[]> = new Map()

  purchasesMap: Map<number, PagedResults<MerchPurchase>> = new Map()

  filterAccountRegions: SubscriptionPlanAccountRegion[] = []

  filterCurrencies: SubscriptionPlanCurrency[] = []

  filterGroups: SubscriptionPlanGroup[] = []

  filterPeriods: SubscriptionPlanPeriod[] = []

  filterPriceTypes: SubscriptionPlanPriceType[] = []

  filterEdshedProducts: SubscriptionProductType[] = []

  filterAccountTypes: SubscriptionPlanAccountType[] = []

  filterLevelTypes: SubscriptionLevelType[] = []

  searchPhrase: string = ''

  subscriptionSearchPhrase: string = ''

  invoiceSearchPhrase: string = ''

  invoiceStatus: StripeInvoiceStatus | null = null

  loading: boolean = false

  persons: SalesPerson[] = []

  purchaseLoading: boolean = false

  showOverdue: boolean = false

  showUSD: boolean = false

  wondeLinkSchoolId: number | null = null

  d6LinkSchoolId: number | null = null

  districtLinkSchoolId: number | null = null

  openedDetailed: SuperUserSchoolSearchResult[] = []

  addSubscription: SuperUserSchoolSearchResult | null = null

  stripeEventsModal: boolean = false

  stripeEventsSchoolId: number | null = null

  resourceDownloadsModal: boolean = false

  resourceDownloadSchoolId: number | null = null

  purchaseTableState: TableState = { page: 1, perPage: 5, sort: 'id', dir: 'desc', term: '' }

  tableState: TableState = { page: 1, perPage: 10, sort: 'id', dir: 'desc', term: '' }

  totalResults: number = 0

  syncingOneRoster: boolean = false

  classLinkSchool: SuperUserSchoolSearchResult | null = null

  gg4lSchool: SuperUserSchoolSearchResult | null = null

  editingField: {
    schoolId: number,
    field: 'name' | 'email' | 'phone',
    value: string
  } | null = null

  xeroContactModal: null | {
    name: string,
    accountId: string,
    accountRegion: StripeInvoiceAccountRegion,
    school: SuperUserSchoolSearchResult
  } = null

  getAddressString = getAddressString

  get totalFilterCount () {
    return this.filterAccountRegions.length + this.filterCurrencies.length + this.filterGroups.length + this.filterPeriods.length + this.filterPriceTypes.length + this.filterEdshedProducts.length + this.filterAccountTypes.length + this.filterLevelTypes.length
  }

  mounted () {
    if (!this.$store.state.user.superuser) {
      this.$router.push('/noaccess')
    }

    this.filterAccountRegions = this.parseQueryEnum('regions', StripeInvoiceAccountRegion) ?? []
    this.filterCurrencies = this.parseQueryEnum('currencies', SubscriptionPlanCurrency) ?? []
    this.filterGroups = this.parseQueryEnum('groups', SubscriptionPlanGroup) ?? []
    this.filterPeriods = this.parseQueryEnum('periods', SubscriptionPlanPeriod) ?? []
    this.filterPriceTypes = this.parseQueryEnum('pricing', SubscriptionPlanPriceType) ?? []
    this.filterEdshedProducts = this.parseQueryEnum('products', SubscriptionProductType) ?? []
    this.filterAccountTypes = this.parseQueryEnum('types', SubscriptionPlanAccountType) ?? []
    this.filterLevelTypes = this.parseQueryEnum('levels', SubscriptionLevelType) ?? []

    this.searchPhrase = typeof this.$route.query.q === 'string' ? this.$route.query.q : ''
    this.subscriptionSearchPhrase = typeof this.$route.query.subq === 'string' ? this.$route.query.subq : ''
    this.invoiceSearchPhrase = typeof this.$route.query.invq === 'string' ? this.$route.query.invq : ''
    this.invoiceStatus = typeof this.$route.query.invoicestatus === 'string' && this.checkStringIsInUnion(this.$route.query.invoicestatus, StripeInvoiceStatus) ? this.$route.query.invoicestatus : null

    this.showOverdue = this.$route.query.overdue === 'true'
    this.showUSD = this.$route.query.showusd === 'true'

    const hasQuery = Object.keys(this.stripUndefProps(this.queryParams)).length > 0

    if (hasQuery) {
      this.searchDidChange()
    }

    this.getSalesPersons()
  }

  async getSalesPersons () {
    try {
      const sales_persons = await Api.getSalesPersons({}, { active: 'Active' })
      this.persons = sales_persons.items
    } catch (err) {
      this.$buefy.toast.open({
        duration: 5000,
        message: 'Could not load sales persons information',
        position: 'is-bottom',
        type: 'is-danger'
      })
    }
  }

  // Delete an organisation
  async deleteSchool (schoolId: number) {
    try {
      // Its a big decision, are they sure?...
      const areSure = await confirm('Are you sure you want to delete?')
      const areReallySure = await confirm('Definitely sure?')

      // If so, delete it
      if (areSure && areReallySure) {
        this.loading = true
        // Request a deletion
        await Api.superuserDeleteSchool(schoolId)

        // Reload the data
        this.searchDidChange()
      }
    } catch (err: unknown) {
      if (err instanceof Error) {
        this.toastError(err.message)
      }
    } finally {
      this.loading = false
    }
  }

  async setSchoolTaxExempt (schoolId: number, status: boolean) {
    this.loading = true

    try {
      await Api.suUpdateSchool(schoolId, { tax_exempt: status })
    } catch (err) {
      if (err instanceof Error) {
        this.toastError(err.message)
      }
    } finally {
      this.loading = false
    }
  }

  openResourceDownloadsModal (schoolId: number) {
    this.resourceDownloadSchoolId = schoolId
    this.resourceDownloadsModal = true
  }

  closeResourceDownloadsModal () {
    this.resourceDownloadsModal = false
    this.resourceDownloadSchoolId = null
  }

  openStripeEventsModal (schoolId: number) {
    this.stripeEventsSchoolId = schoolId
    this.stripeEventsModal = true
  }

  closeStripeEventsModal () {
    this.stripeEventsModal = false
    this.stripeEventsSchoolId = null
  }

  startLinkingXeroContact (school: SuperUserSchoolSearchResult) {
    this.xeroContactModal = {
      accountId: school.id.toString(),
      accountRegion: school.account_region,
      name: school.school_name,
      school
    }
  }

  async linkXeroContact (school: SuperUserSchoolSearchResult, contact: XeroContactInfo) {
    try {
      this.loading = true

      const newSchool = await Api.suUpdateSchool(school.id, { xero_customer: contact.id })
      school.xero_contact = newSchool.xero_contact

      this.$forceUpdate()
    } catch (err) {
      this.toastError('Could not link contact')
    } finally {
      this.loading = false
      this.xeroContactModal = null
    }
  }

  startEditingField (school: SuperUserSchoolSearchResult, field: 'name' | 'email' | 'phone') {
    const locationAddress = school.addresses?.find(a => a.id === school.location_address_id)
    const value = field === 'name' ? locationAddress?.name : field === 'email' ? locationAddress?.email : locationAddress?.phone_number

    if (this.editingField) {
      const oldSchool = this.schoolsData.find(s => s.id === this.editingField!.schoolId)

      if (oldSchool) {
        const oldValue = this.editingField.field === 'name' ? locationAddress?.name : this.editingField.field === 'email' ? locationAddress?.email : locationAddress?.phone_number

        if (oldValue !== this.editingField.value) {
          this.$buefy.dialog.confirm({
            message: 'You have an unsaved edit on this page. Would you like to discard this data?',
            title: 'Unsaved Edit',
            type: 'is-warning',
            onConfirm: () => {
              this.editingField = { schoolId: school.id, field, value: value ?? '' }
            }
          })

          return
        }
      }
    }

    this.editingField = { schoolId: school.id, field, value: value ?? '' }
  }

  async saveFieldChange () {
    if (!this.editingField) {
      return
    }

    const editField = this.editingField

    try {
      this.loading = true

      const school = this.schoolsData.find(s => s.id === editField.schoolId)

      if (!school) {
        throw new Error('Could not find school')
      }

      const locationAddress = school?.addresses?.find(a => a.id === school.location_address_id)

      const key: keyof AddressToEdit = editField.field === 'name' ? 'name' : editField.field === 'email' ? 'email' : 'phone_number'
      if (locationAddress) {
        await Api.editAddress(locationAddress.id, { ...locationAddress, [key]: editField.value }, null)
      } else {
        await Api.createAddress({
          school_id: school.id,
          address_1: '',
          address_2: '',
          country: school.school_country,
          country_code: school.school_country_code,
          county: '',
          email: '',
          lat: null,
          long: null,
          name: '',
          phone_number: '',
          postcode: '',
          town: '',
          zone_code: null,
          [key]: editField.value
        }, 'location_address_id')
      }

      this.editingField = null

      this.$buefy.toast.open({
        message: 'Saved',
        type: 'is-success',
        position: 'is-bottom'
      })

      this.searchDidChange()
    } catch (err: unknown) {
      if (err instanceof Error) {
        this.toastError(err.message)
      }
    } finally {
      this.loading = false
    }
  }

  clearFieldChange () {
    this.editingField = null
  }

  async generateCodeForSchool (schoolId: number) {
    this.loading = true

    try {
      await Api.superuserGenerateCodeForSchool(schoolId)
    } catch (err) {
      if (err instanceof Error) {
        this.toastError(err.message)
      }
    } finally {
      this.loading = false
    }
  }

  moveUsersForSchool (schoolId: number) {
    const newSchool = prompt('Move all users and groups to school? This cannot be undone.', `${schoolId}`)
    if (newSchool) {
      request('POST', 'superuser/schools/' + schoolId + '/moveusers', { school: newSchool })
        .then((response) => {
          this.searchDidChange()
        })
        .catch((error) => {
          this.loading = false
          console.log(error)
          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }
        })
    }
  }

  refreshSessions (schoolId) {
    console.log(schoolId)
    // /superuser/schools/:id/refreshtokens
    request('POST', 'superuser/schools/' + schoolId + '/refreshtokens', null)
      .then((response) => {
        const data = response.data
        console.log(data)
        alert('Success')
      })
      .catch((error) => {
        this.loading = false
        console.log(error)
        if (error.response.status === 403) {
          console.log('FORBIDDEN')
          this.$router.push('/logout')
        }
      })
  }

  saveCleverStatus (school: SuperUserSchoolSearchResult, e: CleverStatus | '') {
    request('POST', 'schools/' + school.id + '/setcleverstatus', { status: e, clever_oid: school.clever_oid })
      .then((response) => {
        const data = response.data
        console.log(data)
      })
      .catch((error) => {
        this.loading = false
        console.log(error)
        if (error.response.status === 403) {
          console.log('FORBIDDEN')
          this.$router.push('/logout')
        }
      })
  }

  saveOrgType (school: SuperUserSchoolSearchResult, e: SchoolOrgType) {
    request('PUT', 'schools/' + school.id + '/orgtype', { value: e })
      .then((response) => {
        const data = response.data

        if (data.error) {
          alert(data.error)
        }

        school.org_type = e
      })
      .catch((error) => {
        this.loading = false
        if (error.response.status === 403) {
          console.log('FORBIDDEN')
          this.$router.push('/logout')
        }
      })
  }

  unlinkWonde (school: SuperUserSchoolSearchResult) {
    this.$buefy.dialog.confirm({
      title: 'Unlink Wonde',
      message: 'Wonde will be disconnected',
      confirmText: 'Unlink',
      type: 'is-danger',
      trapFocus: true,
      onConfirm: async () => {
        try {
          await Api.unlinkWonde(school.id)

          this.$buefy.toast.open({
            duration: 5000,
            message: 'Wonde Disconnected',
            position: 'is-top',
            type: 'is-success'
          })

          school.wonde_id = null
          school.wonde_status = null
        } catch (err: unknown) {
          if (err instanceof Error) {
            this.toastError(err.message)
          }
        }
      }
    })
  }

  unlinkD6 (school: SuperUserSchoolSearchResult) {
    this.$buefy.dialog.confirm({
      title: 'Unlink D6',
      message: 'D6 will be disconnected',
      confirmText: 'Unlink',
      type: 'is-danger',
      trapFocus: true,
      onConfirm: async () => {
        try {
          await Api.unlinkD6(school.id)

          this.$buefy.toast.open({
            duration: 5000,
            message: 'D6 Disconnected',
            position: 'is-top',
            type: 'is-success'
          })

          school.d6_id = null
        } catch (err: unknown) {
          if (err instanceof Error) {
            this.toastError(err.message)
          }
        }
      }
    })
  }

  async syncClever (cleverOid: string) {
    this.loading = true
    try {
      await Api.cleverSync(cleverOid)
    } catch (err: unknown) {
      if (err instanceof Error) {
        this.toastError(err.message)
      }
    } finally {
      this.loading = false
    }
  }

  async syncOneRoster (provider: OneRosterProvider, id: string, tenantId: number | null) {
    this.syncingOneRoster = true
    try {
      await Api.syncOneRoster(id, provider, tenantId)
    } catch (err: unknown) {
      if (err instanceof Error) {
        this.toastError(err.message)
      }
    } finally {
      this.syncingOneRoster = false
    }
  }

  gg4lOrgLinked () {
    this.searchDidChange()

    this.gg4lSchool = null

    this.$buefy.toast.open({
      message: 'Organisation linked',
      position: 'is-bottom',
      type: 'is-success'
    })
  }

  classLinkOrgLinked () {
    this.searchDidChange()

    this.classLinkSchool = null

    this.$buefy.toast.open({
      message: 'Organisation linked',
      position: 'is-bottom',
      type: 'is-success'
    })
  }

  classLinkOrgCreated () {
    this.searchDidChange()
    this.classLinkSchool = null

    this.$buefy.toast.open({
      message: 'Organisation created',
      position: 'is-bottom',
      type: 'is-success'
    })
  }

  showLinkWonde (schId: number) {
    this.wondeLinkSchoolId = schId
  }

  hideLinkWonde () {
    this.wondeLinkSchoolId = null
  }

  showLinkD6 (schId: number) {
    this.d6LinkSchoolId = schId
  }

  hideLinkD6 () {
    this.d6LinkSchoolId = null
  }

  showLinkClever (schoolId: number, cleverOid: string | null) {
    const p = prompt('Enter Clever Object ID', cleverOid ?? '')
    if (p) {
      console.log(p)
      request('POST', 'schools/' + schoolId + '/setcleverid', { clever_oid: p })
        .then((response) => {
          const data = response.data
          console.log(data)
        })
        .catch((error) => {
          this.loading = false
          console.log(error)
          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }
        })
    }
  }

  showLinkOneRoster (school: SuperUserSchoolSearchResult, type: OneRosterProvider) {
    // class link is handled differently.
    if (type === 'class-link') {
      this.classLinkSchool = school
    } else if (type === 'gg4l') {
      this.gg4lSchool = school
    }
  }

  showLinkDistrict (school: SuperUserSchoolSearchResult) {
    this.districtLinkSchoolId = school.id
  }

  unlinkDistrict (school: SuperUserSchoolSearchResult) {
    if (school.org_type === 'district') {
      alert('Cannot')
      return
    }
    const c = confirm('Unlink this district?')
    if (c) {
      request('DELETE', 'districts/' + school.district_id + '/linkschool', { schoolId: school.id })
        .then((response) => {
          this.$buefy.toast.open({
            message: 'Saved',
            type: 'is-success'
          })
          this.searchDidChange()
          // console.log('readerData: ' + this.readerData)
        })
        .catch((error) => {
          console.log(error)
          alert(error.message)
          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }
        })
    }
  }

  hideLinkDistrict () {
    this.searchDidChange()
    this.districtLinkSchoolId = null
  }

  shouldShowPersonalTag (plan: SubscriptionPlanInfo) {
    return isPersonal(plan)
  }

  shouldShowProductTag (basePlan: SubscriptionPlanInfo, addOnPlans: SubscriptionPlanInfo[], product: SubscriptionProductType) {
    return basePlan.product.product_type === product || addOnPlans.find(a => a.product.product_type === product)
  }

  promptCancelScheduledSub (school: SuperUserSchoolSearchResult, schedule: ScheduledSubscriptionInfo) {
    this.$buefy.dialog.confirm({
      message: 'Would you like to cancel this scheduled subscription',
      title: 'Confirm Deletion',
      type: 'is-danger',
      onConfirm: async () => {
        try {
          this.loading = true

          await Api.cancelSchoolsScheduledSubscription(school.id, schedule.id)
          await this.refreshDetail(school)
        } catch (err: unknown) {
          if (err instanceof Error) {
            this.toastError(err.message)
          } else {
            console.log(err)
          }
        } finally {
          this.loading = false
        }
      }
    })
  }

  async refreshDetail (school: SuperUserSchoolSearchResult) {
    await this.didOpenDetail(school)
  }

  searchDidChange () {
    this.openedDetailed = []
    this.getSubscriptionsInfo()
  }

  get queryParams (): GetSchoolSubscriptionsParams {
    const query: GetSchoolSubscriptionsParams = {}

    this.searchPhrase !== '' ? query.q = this.searchPhrase : undefined
    this.subscriptionSearchPhrase !== '' ? query.subq = this.subscriptionSearchPhrase : undefined
    this.invoiceSearchPhrase !== '' ? query.invq = this.invoiceSearchPhrase : undefined
    this.showOverdue ? query.overdue = true : undefined
    this.invoiceStatus ? query.invoicestatus = this.invoiceStatus : undefined
    this.showUSD ? query.showusd = true : undefined
    this.filterGroups.length > 0 ? query.groups = this.filterGroups : undefined
    this.filterCurrencies.length > 0 ? query.currencies = this.filterCurrencies : undefined
    this.filterAccountRegions.length > 0 ? query.regions = this.filterAccountRegions : undefined
    this.filterPriceTypes.length > 0 ? query.pricings = this.filterPriceTypes : undefined
    this.filterPeriods.length > 0 ? query.periods = this.filterPeriods : undefined
    this.filterEdshedProducts.length > 0 ? query.products = this.filterEdshedProducts : undefined
    this.filterAccountTypes.length > 0 ? query.types = this.filterAccountTypes : undefined
    this.filterLevelTypes.length > 0 ? query.levels = this.filterLevelTypes : undefined

    return query
  }

  async getPurchaseInfo (id) {
    this.purchaseLoading = true
    const purchases = await Api.getAllMerchSales({ skip: (this.purchaseTableState.page - 1) * this.purchaseTableState.perPage, take: this.purchaseTableState.perPage, sort: this.purchaseTableState.sort, dir: this.purchaseTableState.dir, term: this.purchaseTableState.term }, { school_id: id })
    this.purchasesMap.set(id, purchases)
    this.$forceUpdate()
    this.purchaseLoading = false
  }

  async getSubscriptionsInfo () {
    this.loading = true

    try {
      const finalQuery = this.stripUndefProps(this.queryParams)
      finalQuery.currencies
      finalQuery.include_addresses = true
      const res = await Api.superuserSearchSchools(finalQuery, { skip: (this.tableState.page - 1) * this.tableState.perPage, take: this.tableState.perPage, sort: this.tableState.sort, dir: this.tableState.dir })
      this.schoolsData = res.items
      this.totalResults = res.total

      this.updateQueryParams(() => this.stringifyProps(this.stripUndefProps(this.queryParams)))
    } catch (err: unknown) {
      if (err instanceof Error) {
        this.toastError(err.message)
      }
    } finally {
      this.loading = false
    }
  }

  pageChanged (page: number) {
    this.tableState.page = page
    this.getSubscriptionsInfo()
  }

  async tableChangedPurchases (table, row) {
    this.purchaseTableState = table
    await this.getPurchaseInfo(row)
  }

  sortChanged (field: string, dir: 'asc' | 'desc') {
    this.tableState.sort = field
    this.tableState.dir = dir
    this.getSubscriptionsInfo()
  }

  async didOpenDetail (row: SuperUserSchoolSearchResult) {
    // load subscriptions for school
    request('GET', 'schoolsubscriptions/' + row.id, null)
      .then((response) => {
        const data = response.data
        this.subscriptionsMap.set(row.id, data.subscriptions)
        this.$forceUpdate()
      })
      .catch((error) => {
        console.log(error)
        if (error.response.status === 403) {
          console.log('FORBIDDEN')
          this.$router.push('/logout')
        }
      })

    const schedules = await Api.getSchoolsScheduledSubscriptions(row.id)
    this.futureSubscriptionsMap.set(row.id, schedules)

    await this.getPurchaseInfo(row.id)
    this.$forceUpdate()
  }

  resetSubFilters () {
    this.filterAccountRegions = []
    this.filterCurrencies = []
    this.filterGroups = []
    this.filterPeriods = []
    this.filterPeriods = []
    this.filterPriceTypes = []
    this.filterEdshedProducts = []
    this.filterAccountTypes = []
    this.filterLevelTypes = []
  }

  downloadAllClicked () {
    this.loading = true

    request('GET', 'schoolsubscriptions/csv', this.queryParams)
      .then((response) => {
        this.loading = false
        this.$buefy.dialog.alert(`A .csv file is being generated. It will be emailed to ${this.$store.state.user.email} when it is ready.`)
      })
      .catch((error) => {
        this.loading = false
        console.log(error)
        if (error.response.status === 403) {
          console.log('FORBIDDEN')
          this.$router.push('/logout')
        }
      })
  }
}

