


























































































































import { avatarImageDimensions, AvatarItemEditRequest, AvatarItemForShop, AvatarItemInfo, AvatarItemLayer, AvatarItemSlot, AvatarLayers, getAvatarItemTypeName, _AvatarColor, _AvatarItemSlot } from '@/edshed-common/avatar/avatarItems'
import { AvatarRenderer } from '@/edshed-common/avatar/avatarRenderer'
import AvatarShopItem from '@/edshed-common/avatar/AvatarShopItem.vue'
import ModalMixin from '@/edshed-common/mixins/ModalMixin'
import ComponentHelper from '@/mixins/ComponentHelper'
import { Mixins, Component, Prop } from 'vue-property-decorator'
import AvatarItem from '@/edshed-common/avatar/AvatarItem.vue'
import ImageModal from '@/edshed-common/components/ImageModal.vue'
import { Api } from '@/edshed-common/api'
import AvatarImageUpload from './AvatarImageUpload.vue'

@Component({ components: { AvatarShopItem, AvatarImageUpload } })
export default class AvatarItemDetails extends Mixins(ModalMixin, ComponentHelper) {
  @Prop({ required: true }) item!: AvatarItemInfo
  @Prop({ required: true }) renderer!: AvatarRenderer

  private avatarPreviewSrc = ''
  private avatarItemPreviewSrc = ''
  private avatarPreviewRedraw = 0

  private showCustomDepthInputs: boolean[] = []

  private formRedraw = 0

  private layerTabIndex = 0

  private lockKeyField = true

  // Based on the AvatarLayers enum
  private layerSelections = [
    { name: 'Background (5)', value: 5 },
    { name: 'Chairs (10)', value: 10 },
    { name: 'Hair (back) (15)', value: 15 },
    { name: 'Wings (20)', value: 20 },
    { name: 'Shirt (25)', value: 25 },
    { name: 'Body (30)', value: 30 },
    { name: 'Head (35)', value: 35 },
    { name: 'Face (40)', value: 40 },
    { name: 'Face Accessories (45)', value: 45 },
    { name: 'Facial Hair (50)', value: 50 },
    { name: 'Hair (front) (55)', value: 55 },
    { name: 'Masks (60)', value: 60 },
    { name: 'Hats (65)', value: 65 },
    { name: 'Held Items (70)', value: 70 },
    { name: 'Pets (75)', value: 75 },
    { name: 'Effects (80)', value: 80 }
  ]

  mounted () {
    this.initialiseItem()
  }

  get previewSrc () {
    return this.avatarPreviewSrc || 'https://files.edshed.com/img/avatar/avatar_loading.png'
  }

  get canSaveForm () {
    if (this.item) {
      if (!this.item.name) { return false }
      if (!this.item.type) { return false }
      if (!this.item.layers.layers.length) { return false }
      // Following is commented out because its not reactive - validated on save
      // if (!this.item.image && !this.item.new_image) { return false }
      // if (!this.validateImage()) { return false }
      return true
    }
    return false
  }

  get colorSlots () {
    return _AvatarColor
  }

  get itemSlots () {
    return _AvatarItemSlot.filter(x => x !== 'default')
  }

  get itemForShop (): AvatarItemForShop {
    return { item: this.item, equipped: false, owned: false, purchased: false }
  }

  private updateForm () {
    this.formRedraw++
  }

  private getSlotName (type: AvatarItemSlot) {
    return getAvatarItemTypeName(type)
  }

  private addLayer () {
    this.item.layers.max_id++
    this.item.layers.layers.push({ id: this.item.layers.max_id, colors: [], layer: this.item.layers.layers[0].layer })
    this.$nextTick(() => {
      this.layerTabIndex = Math.max(0, this.item.layers.layers.length - 1)
    })
  }

  private async deleteLayer (index: number) {
    if (await this.deleteConfirm({ name: '' }, { title: 'Delete Layer?', message: 'Delete this layer?' })) {
      this.item.layers.layers.splice(index, 1)
    }
  }

  private async onLayerColorCheckbox (layer: AvatarItemLayer) {
    if (!layer) { return }
    if (!layer.colors.length) {
      await this.validateLayer(layer)
      if (!layer.colors.length) {
        layer.colors = ['']
      }
    } else {
      layer.colors = []
    }
    this.updateAvatarPreview()
  }

  private changeItemType () {
    if (!this.item) { return }
    this.updateAvatarPreview()
  }

  private onItemColorChange () {
    this.updateAvatarPreview()
  }

  private async initialiseItem () {
    if (this.item.id === -1) {
      // this.updateAvatarPreview()
    } else if (await this.validateLayers()) {
      this.updateAvatarPreview()
    }
  }

  private async onLayerImageUpdate (layer: AvatarItemLayer) {
    if (await this.validateLayer(layer)) {
      this.updateAvatarPreview()
    }
  }

  private showSpritesheetHelp () {
    this.$buefy.modal.open({
      component: ImageModal,
      props: {
        title: '',
        image: { fullSizePath: '/img/avatartutorial.png' }
      },
      hasModalCard: true,
      events: { },
      canCancel: true,
      onCancel: () => { }
    })
  }

  private async validateLayer (layer: AvatarItemLayer) {
    if (!layer) { return false }
    if (!layer.image && !layer.new_image) {
      // No images
      layer.colors = []
      // this.alert({title: 'Validation Error', message: 'A layer is missing an image.'})
      console.log('no image')
      return false
    }
    let url = ''
    if (layer.image && !layer.new_image) {
      // exisiting image
      url = layer.image.fullSizePath || ''
    } else if (layer.new_image) {
      // new image
      url = layer.new_image.data || ''
    }
    if (!url) {
      this.alert({ title: 'Validation Error', message: 'Image data is invalid' })
    }
    const img = await this.getImageElementFromURL(url)
    if (!img) {
      this.alert({ title: 'Validation Error', message: 'Image data is invalid' })
      return false
    }

    const { height, width } = img
    if (height === avatarImageDimensions.height && width === avatarImageDimensions.width) {
      // All good, one single image
      if (layer.colors.length) {
        layer.colors = layer.colors.slice(0, 1)
      }
      return true
    } else if (height === avatarImageDimensions.height) {
      const framesfloat = width / avatarImageDimensions.width
      const frames = Math.floor(framesfloat)
      if (frames !== framesfloat) {
        console.error('These layers are spread out improperly!')
        this.alert({ title: 'Validation Error', message: `Image size is not correct. If image is wider than ${avatarImageDimensions.width}px then it must be a multiple of ${avatarImageDimensions.width} to be split into frames.` })
        return false
      } else {
        if (layer.colors.length) {
          if (layer.colors.length < frames) {
            for (let i = layer.colors.length; i < frames; i++) {
              layer.colors.push('')
            }
          } else {
            layer.colors = layer.colors.slice(0, frames)
          }
        } else {
          for (let i = 0; i < frames; i++) {
            layer.colors.push('')
          }
        }

        return true
      }
    } else {
      console.error('What are you trying to pull? This image here is the wrong size!')
      this.alert({ title: 'Validation Error', message: `Image size is not correct. Image must have a height of ${avatarImageDimensions.height}px and width must be a multiple of ${avatarImageDimensions.width}.` })
      return false
    }
  }

  private async validateLayers () {
    if (!this.item.layers.layers.length) { return false }
    for (let i = 0; i < this.item.layers.layers.length; i++) {
      if (await !this.validateLayer(this.item.layers.layers[i])) {
        return false
      }
    }
    return true
  }

  private getImageElementFromURL (url: string): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
      const img = new Image()
      if (!url) { resolve(img); return }
      img.src = url
      img.onload = () => {
        resolve(img)
      }
      img.onerror = () => {
        resolve(img)
      }
    })
  }

  private async updateAvatarPreview () {
    this.avatarPreviewRedraw++
    if (await this.validateLayers()) {
      this.renderer.resetToDefault()
      this.renderer!.addItem(this.item!)
      const canvasSingleItem = await this.renderer!.getItemShopImage(this.item!)
      this.avatarItemPreviewSrc = canvasSingleItem.toDataURL()
      const canvas = await this.renderer!.getAvatarImageURL()
      this.avatarPreviewSrc = canvas.toDataURL()

      this.avatarPreviewRedraw++
    }
  }

  private async saveItem () {
    if (!this.item) { return }
    if (!this.canSaveForm) { return }
    if (!this.item.layers.layers.length || this.item.layers.layers.filter(x => !x.image && !x.new_image).length) {
      this.alert({ title: 'Validation Error', message: 'Layer image is missing - please upload an image.' })
      return
    }
    if (await !this.validateLayers()) {
      this.alert({ title: 'Validation Error', message: 'Image layers are invalid. Check image resolutions are correct.' })
      return
    }

    if (this.item.new_image) {
      if (await !this.validateShopImage) {
        this.alert({ title: 'Validation Error', message: 'Shop image is invalid. Check image resolution is correct.' })
        return
      }
    } else if (!this.item.image) {
      this.alert({ title: 'Validation Error', message: 'Shop image is missing - please upload an image.' })
      return
    }
    if (typeof this.item.cost === 'string') {
      this.item.cost = parseInt(this.item.cost) || 0
    }

    try {
      if (this.item.id < 0) {
        await Api.createAvatarItem(this.item)
        this.$buefy.toast.open({
          message: 'Item created successfully',
          type: 'is-success',
          position: 'is-bottom',
          duration: 3000
        })
      } else {
        const { name, type, cost, hidden, colors } = this.item
        const editPayload: AvatarItemEditRequest = {
          name,
          type,
          cost,
          hidden,
          colors,
          layers: this.item.layers
        }
        if (this.item.new_image !== undefined) {
          editPayload.new_image = this.item.new_image
        }
        if (this.item.new_image !== undefined) {
          editPayload.new_image = this.item.new_image
        }
        await Api.editAvatarItem(this.item.key, editPayload)
        this.$buefy.toast.open({
          message: 'Item updated successfully',
          type: 'is-success',
          position: 'is-bottom',
          duration: 3000
        })
      }
      this.$emit('saved')
    } catch (error: unknown) {
      if (error instanceof Error) {
        this.alert({ title: error.name, message: error.message, console: error.stack })
      }
    }
  }

  private enableCustomDepth (layerIndex: number) {
    this.showCustomDepthInputs[layerIndex] = true
  }

  private disableCustomDepth (layerIndex: number) {
    this.showCustomDepthInputs[layerIndex] = false
    const layer = this.item.layers.layers[layerIndex]
    if (layer) {
      let highest = 0
      for (let i = 0; i < this.layerSelections.length; i++) {
        if (layer.layer < this.layerSelections[i].value) {
          break
        }
        highest = this.layerSelections[i].value
      }
      layer.layer = highest
    }
  }

  private toggleCustomDepth (layerIndex: number) {
    if (this.showCustomDepthInputs[layerIndex]) {
      this.disableCustomDepth(layerIndex)
    } else {
      this.enableCustomDepth(layerIndex)
    }
    this.updateForm()
  }

  private onShopImageUpdate () {
    this.validateShopImage()
    this.updateAvatarPreview()
  }

  private async onShopImageColorCheckbox () {
    if (!this.item.colors.length) {
      await this.validateShopImage()
      if (!this.item.colors.length) {
        this.item.colors = ['']
      }
    } else {
      this.item.colors = []
    }
    this.updateAvatarPreview()
  }

  private refreshImages () {
    this.updateAvatarPreview()
  }

  private async validateShopImage () {
    if (!this.item.image && !this.item.new_image) {
      // No images
      this.item.colors = []
      return false
    }
    let url = ''
    if (this.item.image && !this.item.new_image) {
      // exisiting image
      url = this.item.image.fullSizePath || ''
    } else if (this.item.new_image) {
      // new image
      url = this.item.new_image.data || ''
    }
    if (!url) {
      this.alert({ title: 'Validation Error', message: 'Image data is invalid' })
    }
    const img = await this.getImageElementFromURL(url)
    if (!img) {
      this.alert({ title: 'Validation Error', message: 'Image data is invalid' })
      return false
    }

    const { width } = img
    console.log('width', width, 'compared to', avatarImageDimensions.width)
    if (width === avatarImageDimensions.width) {
      // All good, one single image
      console.log('all good')
      if (this.item.colors.length) {
        this.item.colors = this.item.colors.slice(0, 1)
      }
      return true
    } else {
      console.log('got frames')
      const framesfloat = width / avatarImageDimensions.width
      const frames = Math.floor(framesfloat)
      if (frames !== framesfloat) {
        console.error('These layers are spread out improperly!')
        this.alert({ title: 'Validation Error', message: `Image size is not correct. If image is wider than ${avatarImageDimensions.width}px then it must be a multiple of ${avatarImageDimensions.width} to be split into frames.` })
        return false
      } else {
        if (this.item.colors.length) {
          if (this.item.colors.length < frames) {
            for (let i = this.item.colors.length; i < frames; i++) {
              this.item.colors.push('')
            }
          } else {
            this.item.colors = this.item.colors.slice(0, frames)
          }
        } else {
          for (let i = 0; i < frames; i++) {
            this.item.colors.push('')
          }
        }

        return true
      }
    }
  }

  private depthIsCustom (depth: number) {
    return !this.layerSelections.find(x => x.value === depth)
  }
}
